import React, { useEffect, useState } from 'react';
import { AppInstanceState } from '../api/generated';
import Button from './base/Button';
import { RunAppButton } from './base/RunAppButton';
import StopAppIcon from 'remixicon-react/StopFillIcon';
import DatabaseIcon from 'remixicon-react/Database2LineIcon';
import GlobalIcon from 'remixicon-react/GlobalLineIcon';
import * as dataTestidConstants from '../constants';
import classNames from 'classnames';
import { useChatStore } from '../store/chat';
import { FrontendEvents } from '../api/StatsApi';
import { isMobileDevice } from '../utils/deviceDimensions';
import { getAuthCookie } from '../api/TtyHelper';
import { toast } from 'react-toastify';
import { TOP_BAR_VIEW_DATABASE_BUTTON } from '../constants';

interface AppInstanceStatusDisplayProps {
  state: AppInstanceState;
  onStopApp: () => void;
  onStartApp: (ramMb: number, sutoUpdateTestApp: boolean) => void;
  isNewVersionAvailable?: boolean;
  isRestarting?: boolean;
  isStopping?: boolean;
  isRunning?: boolean;
  openAppUrl: string | null;
  sqliteWebUrl: string | null;
  emitEvent: (eventType: FrontendEvents) => void;
  currentRam?: number;
  isTestingApp?: boolean;
}

const AppInstanceStatusDisplay = React.forwardRef<HTMLDivElement, AppInstanceStatusDisplayProps>(
  // eslint-disable-next-line max-lines-per-function
  (
    {
      state,
      onStopApp,
      onStartApp,
      isNewVersionAvailable,
      isRestarting,
      isRunning,
      isStopping,
      openAppUrl,
      sqliteWebUrl,
      emitEvent,
      currentRam,
      isTestingApp,
    },
    ref
  ) => {
    const shouldAllowStartingApp =
      [
        AppInstanceState.ENDED,
        AppInstanceState.STOPPED,
        AppInstanceState.ENDED_WITH_ERROR,
        AppInstanceState.UNKNOWN_ERROR,
        AppInstanceState.NEVER_RUN,
      ].indexOf(state) >= 0;

    const shouldAllowStoppingApp = !shouldAllowStartingApp;

    const stateFirstRun = state === AppInstanceState.NEVER_RUN;
    const error = useChatStore((state) => state.error);

    const [openAppUrlToRender, setOpenAppUrlToRender] = useState<string | null>(null);
    const [sqliteWebUrlToRender, setSqliteWebUrlToRender] = useState<string | null>(null);

    useEffect(() => {
      if (openAppUrl) {
        if (!openAppUrlToRender) {
          setTimeout(() => setOpenAppUrlToRender(openAppUrl), 2000);
        }
      } else {
        setOpenAppUrlToRender(null);
      }
    }, [openAppUrl]);

    useEffect(() => {
      if (sqliteWebUrl) {
        if (!sqliteWebUrlToRender) {
          setTimeout(() => setSqliteWebUrlToRender(sqliteWebUrl), 2000);
        }
      } else {
        setSqliteWebUrlToRender(null);
      }
    }, [sqliteWebUrl]);

    if (error) {
      return null;
    }

    return (
      <div ref={ref} className="flex gap-2">
        {isRunning && sqliteWebUrlToRender && (
          <Button
            className={classNames('text-system-accent bg-white border border-system-accent')}
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_SHOW_DB_BUTTON);
              getAuthCookie()
                .then(() => {
                  window.open(sqliteWebUrlToRender, '_blank');
                  return null;
                })
                .catch(() => {
                  toast.error('Something went wrong. Please refresh the page and try again.');
                });
            }}
            iconProps={{ icon: DatabaseIcon, iconSize: 20 }}
            dataTestid={dataTestidConstants.TEST_APP_VIEW_DATABASES_APP_BUTTON as string}
          >
            {!isMobileDevice() && (
              <span data-testid={TOP_BAR_VIEW_DATABASE_BUTTON}>View database</span>
            )}
          </Button>
        )}
        {isRunning && openAppUrlToRender && isMobileDevice() && (
          <Button
            className={classNames('bg-system-accent text-white hover:bg-system-accent/80')}
            onClick={() => {
              emitEvent(FrontendEvents.USER_CLICKED_OPEN_APP_BUTTON);
              window.open(openAppUrlToRender, '_blank');
            }}
            iconProps={{ icon: GlobalIcon, iconSize: 20 }}
            dataTestid={dataTestidConstants.TEST_APP_OPEN_APP_BUTTON as string}
          >
            Open app
          </Button>
        )}
        {shouldAllowStartingApp && (
          <RunAppButton
            isAppRestarting={!!isRestarting}
            onRunApp={onStartApp}
            isFirstRun={stateFirstRun}
            isNewVersionAvailable={isNewVersionAvailable}
            currentRam={currentRam}
            isTestingApp={isTestingApp}
          />
        )}
        {shouldAllowStoppingApp && (
          <Button
            className={classNames(
              'bg-white text-system-danger hover:bg-white/80 border border-system-danger',
              {
                'cursor-not-allowed': isStopping,
              }
            )}
            onClick={onStopApp}
            iconProps={{ icon: StopAppIcon, iconSize: 20 }}
            disabled={isStopping}
            loading={isStopping}
            dataTestid={dataTestidConstants.TEST_APP_STOP_APP_BUTTON}
          >
            {!isMobileDevice() && <span> Stop App</span>}
          </Button>
        )}
      </div>
    );
  }
);

export default AppInstanceStatusDisplay;
