import { create } from 'zustand';

interface TabStore {
  id: string;
  isVSCodeLoaded: boolean;
  setVSCodeLoaded: (loaded: boolean) => void;

  vscodeConnectionStatus: string;
  setVSCodeConnectionStatus: (status: string) => void;
}

export const useTabStore = create<TabStore>((set) => ({
  id: '',
  isVSCodeLoaded: true,
  setVSCodeLoaded: (loaded) => {
    set({ isVSCodeLoaded: loaded });
  },
  vscodeConnectionStatus: '',
  setVSCodeConnectionStatus: (status) => {
    set({ vscodeConnectionStatus: status });
  },
}));
