import React, { Ref, useEffect, useRef, useState } from 'react';
import { ReactComponent as CrossIcon } from '../../../assets/cross-icon.svg';
import useModalVisibility from '../../../hooks/modalVisibility';
import GlobalModalBackdrop from '../../../components/GlobalModalBackdrop';
import classNames from 'classnames';
import { isMobileDevice } from '../../../utils/deviceDimensions';
import { File } from '../../../api/generated/models/File';
import UploadCouldLineIcon from 'remixicon-react/UploadCloud2LineIcon';
import FileIcon from 'remixicon-react/File2LineIcon';
import ActionButton from '../../../components/base/ActionButton';
import { FileInfo, FileUploadDetails } from '../../../api/generated';
import { computeHash, sendFilesToBeUploaded } from '../../../components/VSCodeEditor/utils';
import { useAppStore } from '../../../store/app';
import { toast } from 'react-toastify';
import { FileUploadFile } from '../../VSCodeEditor/models';
interface FileUploadModalProps {
  onHide: () => void;
  missingFiles?: FileUploadFile[];
  updateFiles: (updatedFiles: File[]) => Promise<void>;
}

// eslint-disable-next-line max-lines-per-function, max-statements
const FileUploadModal = ({ onHide, missingFiles, updateFiles }: FileUploadModalProps) => {
  const { itemRef, isVisible } = useModalVisibility(true);
  const [currentSelectedFile, setCurrentSelectedFile] = useState<File>({});
  const [isFilesBeingHosted, setIsFilesBeingHosted] = useState<boolean>(false);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState<boolean>(true);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentMissingFiles, setCurrentMissingFiles] = useState<FileUploadFile[]>([]);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<FileInfo[]>([]);

  const clearErrorsFromMissingFiles = (): FileUploadFile[] => {
    return currentMissingFiles.map((file) => {
      file.hasError = false;
      return file;
    });
  };

  const addErrorsToFiles = (uploadResponseFiles: FileUploadDetails[]) => {
    missingFiles = missingFiles?.map((missingFile) => {
      const fileFromResponseWithError = uploadResponseFiles.find(
        (responseFile) =>
          responseFile.name === missingFile.name &&
          responseFile.path === missingFile.path &&
          responseFile.content_hash === ''
      );
      if (fileFromResponseWithError) {
        missingFile.hasError = true;
      }
      return missingFile;
    });
  };

  useEffect(() => {
    if (!isVisible) {
      setCurrentMissingFiles(clearErrorsFromMissingFiles());
      onHide();
    }
  }, [isVisible]);

  useEffect(() => {
    setCurrentMissingFiles(missingFiles || []);
  }, []);
  useEffect(() => {
    setCurrentMissingFiles(missingFiles || []);
  }, [missingFiles]);

  // eslint-disable-next-line max-lines-per-function
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      // eslint-disable-next-line max-lines-per-function
      reader.onload = (e) => {
        if (e.target?.result) {
          const base64String = (e.target.result as string).split(',')[1];
          // eslint-disable-next-line max-len
          // eslint-disable-next-line @typescript-eslint/no-floating-promises, promise/catch-or-return
          computeHash(base64String).then((computedContentHash) => {
            if (computedContentHash) {
              const fileToBeUploaded: FileInfo = {
                b64_content: base64String,
                content_hash: computedContentHash,
                name: currentSelectedFile.name as string,
                path: currentSelectedFile.path as string,
              };

              const tempFilesToBeUploaded = filesToBeUploaded;
              const previouslyUploadedFile = tempFilesToBeUploaded.find(
                (file) => file.name === fileToBeUploaded.name && file.path === fileToBeUploaded.path
              );
              if (!previouslyUploadedFile) {
                tempFilesToBeUploaded.push(fileToBeUploaded);
              } else {
                tempFilesToBeUploaded.forEach((file) => {
                  if (file.name === fileToBeUploaded.name && file.path === fileToBeUploaded.path) {
                    file.b64_content = fileToBeUploaded.b64_content;
                    file.content_hash = fileToBeUploaded.content_hash;
                  }
                });
              }
              setFilesToBeUploaded(tempFilesToBeUploaded);
              return setIsUploadButtonDisabled(tempFilesToBeUploaded.length === 0);
            }
            return [];
          });
        }
      };
      // Read the file as an ArrayBuffer
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSendFilesToBackendAndSave = () => {
    setIsFilesBeingHosted(true);
    const filesToBeHostedPromises = sendFilesToBeUploaded(filesToBeUploaded);

    // eslint-disable-next-line promise/catch-or-return
    Promise.all(filesToBeHostedPromises)
      .then((response: FileUploadDetails[]) => {
        const currentAppFiles = useAppStore.getState().appFiles;
        const filesToBeSaved: File[] = [];
        currentAppFiles.forEach((file) => {
          const tempFile = response.find(
            (uploadedFile) => uploadedFile.name === file.name && uploadedFile.path === file.path
          );

          if (tempFile && tempFile.content_hash) {
            file.content = '';
            file.content_hash = tempFile.content_hash;
            file.content_metadata = tempFile.content_metadata;
          }
          filesToBeSaved.push(file);
        });
        setIsFilesBeingHosted(false);
        if (response.some((file) => file.content_hash === '')) {
          return addErrorsToFiles(response);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateFiles(filesToBeSaved);
          return onHide();
        }
      })
      .catch(() => {
        toast.error('Something went wrong. Please refresh the page and try again.');
      });
  };

  const handleFileUpload = (file: File) => {
    setCurrentSelectedFile(file);
    fileInputRef.current?.click();
  };

  return (
    <div className="flex items-center justify-center h-full w-full fixed top-0 z-[55]">
      <input
        ref={fileInputRef}
        type="file"
        className="hidden" // Hide the input
        onChange={handleFileChange}
      />
      <GlobalModalBackdrop />
      <div
        ref={itemRef as Ref<HTMLDivElement>}
        className={classNames('rounded-md bg-white shadow-2xl z-[100]', {
          'h-full w-screen md:max-w-xl md:h-auto': !isMobileDevice(),
          'h-[80%] w-[85%] mr-[100px]': isMobileDevice(),
        })}
      >
        <div
          className={classNames('flex flex-col overflow-scroll', {
            'h-full': isMobileDevice(),
            'h-96': !isMobileDevice(),
          })}
        >
          <div className="flex flex-row justify-between p-4">
            <div className="font-semibold text-base">Add missing files</div>
            <button onClick={onHide} disabled={isFilesBeingHosted}>
              <CrossIcon className="w-5" />
            </button>
          </div>
          <hr />

          <div className="h-full">
            <div className="flex flex-col items-center justify-center w-full">
              {currentMissingFiles?.map((file) => (
                <div
                  key={`${file.path}${file.name}`}
                  className={classNames('flex border-b w-full items-center')}
                >
                  <div className="flex p-4 w-full">
                    <div
                      className={classNames(
                        'flex text-subtitle-custom-gray',
                        'text-xs text-400 items-center w-full flex-col'
                      )}
                    >
                      <span className="flex w-full">{file.name}</span>
                      {file.hasError && (
                        <span className="flex w-full text-[9px] text-red-500">
                          An error occurred while uploading this file
                        </span>
                      )}
                    </div>
                    <div className="flex justify-end w-full">
                      <ActionButton
                        fillState="filled"
                        size="small"
                        className="justify-end"
                        onClick={() => handleFileUpload(file)}
                      >
                        <FileIcon size={16} />
                        Select file
                      </ActionButton>
                    </div>
                  </div>
                </div>
              ))}
              <div />
            </div>
          </div>
          <div>
            <hr />
            <div className="flex px-6 py-4 gap-[10px] justify-end">
              <ActionButton
                className={classNames('w-[100px] text-label-primary font-semibold border')}
                onClick={onHide}
                disabled={isFilesBeingHosted}
              >
                Cancel
              </ActionButton>
              <ActionButton
                className={classNames('w-[100px] font-medium text-white bg-system-green-7')}
                onClick={handleSendFilesToBackendAndSave}
                isLoading={isFilesBeingHosted}
                disabled={isUploadButtonDisabled}
              >
                <UploadCouldLineIcon size={16} />
                Upload
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
