import React from 'react';
import { AppMemoryOptions, AppMemoryOptionsProps } from './AppMemoryOptions';
import { AutoUpdateTestApp } from './AutoUpdateTestAppSwitch';

export interface RunAppButtonDropdownContentProps {
  appMemoryOptionsProps: AppMemoryOptionsProps;
  leftAlignDropDown?: boolean;
  isTestingApp?: boolean;
}
export const RunAppButtonDropdownContent = (props: RunAppButtonDropdownContentProps) => {
  return (
    <>
      {props.isTestingApp && process.env.REACT_APP_ENABLE_AUTOMATIC_TEST_APP_UPDATE === 'true' && (
        <AutoUpdateTestApp />
      )}
      <AppMemoryOptions currentRam={props.appMemoryOptionsProps.currentRam} />
    </>
  );
};
