import React, { useRef, useContext, useState, useEffect } from 'react';
import * as yaml from 'js-yaml';
import {
  Message,
  MessageContent,
  MessageSource,
  MessageSourceType,
  MessageType,
  TextMessageContent,
  ErrorTracebackFixRequest,
} from '../../types';
import TextMessage from './messages/TextMessage';
import LoopLeftIcon from '../../assets/loop-left-blue.svg';

import BuilderSignInMessage from './messages/BuilderSignInMessage';
// eslint-disable-next-line max-len
import OauthAuthenticationRequirementMessage from './messages/OauthAuthenticationRequirementMessage';
// eslint-disable-next-line max-len
import ApiKeyAuthenticationRequirementMessage from './messages/ApiKeyAuthenticationRequirementMessage';
// eslint-disable-next-line max-len
import CustomAuthenticationRequirementMessage from './messages/CustomAuthenticationRequirementMessage';
import LinkCardMessage from './messages/LinkCardMessage';
import { AppInstanceState, UserAuthenticationRequirement } from '../../api/generated/index';
import LazyAvatar from '../../assets/sloth-avatar-blue.svg';
import LazyTestUserAvatar from '../../assets/test-user-profile.svg';
import classNames from 'classnames';
import { isMobileDevice } from '../../utils/deviceDimensions';
import { INSTANCE_STATE_DESCRIPTION } from '../../i18n';
import InstanceStatusChangeMessage from './messages/InstanceStatusChangeMessage';
import MessageTimestamp from './MessageTimestamp';
import { useAppStore } from '../../store/app';
import { useAuthStore } from '../../store/auth';
import UploadCouldLineIcon from 'remixicon-react/UploadCloud2LineIcon';
import KeyIcon from 'remixicon-react/KeyLineIcon';
import { useChatStore, LOGS_LOADING_ERROR_MESSAGE } from '../../store/chat';
import ActionButton from '../../components/base/ActionButton';
import { AppRunContext } from '../../pages/AppRun';
import {
  TEST_LOAD_PREVIOUS_MESSAGES_BUTTON,
  TabBarItemsIndex,
  ADD_MORE_MEMORY_BUTTON,
} from '../../constants';
import { useEventEmitter } from '../eventEmitterHook';
import { FrontendEvents } from '../../api/StatsApi';
import { toast } from 'react-toastify';

interface MessageListProps {
  messages: Message[];
  source: MessageSource;
  sourceSuffix: string;
  isFirstGroup: boolean;
  isLastGroup: boolean;
  scrollDownToLatestMessage?: () => void;
  isAllPreviousMessagesLoaded: boolean;
  loadMoreMessages: () => Promise<void>;
}

const concatenateErrorListToString = (errorTraceback: string[]) => {
  return errorTraceback.map((error) => error.trim()).join('\n');
};

const sendTracebackToIframeParent = (errorTraceback: string[], appId: string) => {
  const errorTracebackFixRequest: ErrorTracebackFixRequest = {
    errorTraceback: concatenateErrorListToString(errorTraceback),
    tryToFixError: true,
    missingEnvVarError: false,
    appId,
  };
  window.parent.postMessage(errorTracebackFixRequest, '*');
};

export const formatPromptFromTryToFixItFromString = (errorTraceback: string) => {
  const tracebackMessage = errorTraceback.startsWith('I got')
    ? ''
    : 'I got the following exception when I ran the application: \n\n';
  return `${tracebackMessage}${errorTraceback}`.slice(-2000);
};

const formatPromptFromTryToFixIt = (errorTraceback: string[]) => {
  return formatPromptFromTryToFixItFromString(concatenateErrorListToString(errorTraceback));
};

const openSecretsTabInNewWindow = (appId: string) => {
  const url = `/apps/${appId}/build?tab=${TabBarItemsIndex.APP_ENV_SECRETS}`;
  window.open(url, '_blank');
};

const openSecretsTabInIframeParent = (appId: string) => {
  const errorTracebackFixRequest: ErrorTracebackFixRequest = {
    errorTraceback: '',
    tryToFixError: false,
    missingEnvVarError: true,
    appId,
  };
  window.parent.postMessage(errorTracebackFixRequest, '*');
};

const doesErrorContainMissingEnvVarReference = (errorTraceback: string[]) => {
  const errorTracebackString = concatenateErrorListToString(errorTraceback);
  const regexPattern = /File\s"<frozen os>",\sline\s\d+,\sin\s__getitem__\sKeyError:\s'/;
  return regexPattern.test(errorTracebackString);
};

const doesErrorContainOutOfMemoryMessage = (errorTraceback: string[]) => {
  const errorTracebackString = concatenateErrorListToString(errorTraceback).toLowerCase();
  const oomMessage = 'the app was killed due to out of memory error (oom)';
  return errorTracebackString.includes(oomMessage);
};

const sendTracebackAsAPromptInNewWindow = (errorTraceback: string[], appId: string) => {
  // Note: Since this is a new window, it will look like the user sent the prompt manually.
  // Hence, we prefix the prompt with "@eng" to make sure that the developer handles the message
  // TODO: Remove the prefix once we can set the BuilderMessageOrigin for prompts in new windows
  const errorTracebackPrompt = `@eng ${formatPromptFromTryToFixIt(errorTraceback)}`;
  const url = `/apps/${appId}/build?prompt=${encodeURIComponent(errorTracebackPrompt)}`;
  window.open(url, '_blank');
};

const isInsideIframe = window.self !== window.top;

const renderFixThisErrorButton = (
  emitEvent: (eventType: FrontendEvents, appId?: string, instanceId?: string) => void,
  errorTraceback: string[],
  isNewVersionAvailable: boolean,
  appId?: string,
  instanceId?: string
): React.JSX.Element | null => {
  if (isNewVersionAvailable || !appId) {
    return null;
  }
  return (
    <div
      className={classNames('max-w-screen-md w-screen', {
        'p-3': isMobileDevice(),
      })}
    >
      <ActionButton
        size="large"
        buttonType="primary"
        onClick={() => {
          emitEvent(FrontendEvents.USER_CLICKED_TRY_TO_FIX_THIS_ERROR_BUTTON, appId, instanceId);
          isInsideIframe
            ? sendTracebackToIframeParent(errorTraceback, appId)
            : sendTracebackAsAPromptInNewWindow(errorTraceback, appId || '');
        }}
        className="bg-system-danger py-1.5 !px-2.5"
      >
        Try to fix this error
      </ActionButton>
    </div>
  );
};

const renderAddEnvSecretsButton = (
  emitEvent: (eventType: FrontendEvents, appId?: string, instanceId?: string) => void,
  isNewVersionAvailable: boolean,
  appId?: string,
  instanceId?: string
): React.JSX.Element | null => {
  if (isNewVersionAvailable || !appId) {
    return null;
  }
  return (
    <div
      className={classNames('max-w-screen-md w-screen', {
        'p-3': isMobileDevice(),
      })}
    >
      <ActionButton
        size="large"
        buttonType="primary"
        onClick={() => {
          emitEvent(FrontendEvents.USER_CLICKED_ADD_ENV_SECRETS_BUTTON, appId, instanceId);
          isInsideIframe ? openSecretsTabInIframeParent(appId) : openSecretsTabInNewWindow(appId);
        }}
        className="bg-system-accent py-1.5 !px-2.5"
      >
        <KeyIcon size={20} /> Add env secrets
      </ActionButton>
    </div>
  );
};

const renderAddMoreMemoryButton = (
  emitEvent: (eventType: FrontendEvents, appId?: string, instanceId?: string) => void,
  isNewVersionAvailable: boolean,
  appId?: string,
  instanceId?: string
): React.JSX.Element | null => {
  if (isNewVersionAvailable || !appId) {
    return null;
  }
  return (
    <div
      className={classNames('max-w-screen-md w-screen', {
        'pt-3': isMobileDevice(),
      })}
    >
      <ActionButton
        size="large"
        buttonType="primary"
        data-testid={ADD_MORE_MEMORY_BUTTON}
        onClick={() => {
          emitEvent(FrontendEvents.USER_CLICKED_ADD_MORE_MEMORY_BUTTON, appId, instanceId);
          useChatStore.setState({ isMemorySelectorDropdownOpen: true });
        }}
        className="bg-system-accent py-1.5 !px-2.5"
      >
        Add more memory
      </ActionButton>
    </div>
  );
};

const renderMessageActionButtons = (
  emitEvent: (eventType: FrontendEvents, appId?: string, instanceId?: string) => void,
  errorTraceback: string[],
  isNewVersionAvailable: boolean,
  appId?: string,
  instanceId?: string
): React.JSX.Element | null => {
  if (doesErrorContainMissingEnvVarReference(errorTraceback)) {
    return renderAddEnvSecretsButton(emitEvent, isNewVersionAvailable, appId, instanceId);
  } else if (doesErrorContainOutOfMemoryMessage(errorTraceback)) {
    return renderAddMoreMemoryButton(emitEvent, isNewVersionAvailable, appId, instanceId);
  } else {
    return renderFixThisErrorButton(
      emitEvent,
      errorTraceback,
      isNewVersionAvailable,
      appId,
      instanceId
    );
  }
};

// eslint-disable-next-line max-lines-per-function
const renderLoadPreviousMessagesButton = (
  isAppRunMode: boolean,
  isAllPreviousMessagesLoaded: boolean,
  isLoading: boolean,
  loadMoreMessages: () => Promise<void>,
  setIsLoading: (boolean) => void,
  instanceId?: string
  // eslint-disable-next-line max-params
): React.JSX.Element | null => {
  if (isAllPreviousMessagesLoaded) {
    return null;
  }

  if (isAppRunMode && !instanceId) {
    return null;
  }

  return (
    <div className={classNames('max-w-screen-md w-screen')}>
      <ActionButton
        data-testid={TEST_LOAD_PREVIOUS_MESSAGES_BUTTON}
        className="border-system-accent border !text-system-accent"
        size="small"
        buttonType="primary"
        fillState="subtle"
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);
          // eslint-disable-next-line promise/catch-or-return
          loadMoreMessages()
            .catch((e) => {
              toast.error('Something went wrong in loading previous messages.');
              // eslint-disable-next-line
              console.error(e);
            })
            .finally(() => setIsLoading(false));
        }}
      >
        Load previous messages
      </ActionButton>
    </div>
  );
};

// eslint-disable-next-line max-lines-per-function
const renderMessage = (
  messageContent: MessageContent,
  messageIndex: number,
  messagesWithDates: Message[],
  isAppRun: boolean
) => {
  let text = '';
  switch (messageContent.type) {
    case MessageType.AuthenticationRequirement:
      if (messageContent.authType === UserAuthenticationRequirement.type.OAUTH) {
        return <OauthAuthenticationRequirementMessage authenticationInfo={messageContent} />;
      } else if (messageContent.authType === UserAuthenticationRequirement.type.API_KEY) {
        return <ApiKeyAuthenticationRequirementMessage authenticationInfo={messageContent} />;
      } else {
        return <CustomAuthenticationRequirementMessage authenticationInfo={messageContent} />;
      }
    case MessageType.Text:
      text = messageContent.text.trim();
      if (text === LOGS_LOADING_ERROR_MESSAGE && isAppRun) {
        return (
          <div className="">
            <span className="text-system-danger text-sm">{text}</span>
            <ActionButton
              className="border-system-accent border mt-1 text-sm"
              size="small"
              buttonType="primary"
              fillState="subtle"
              onClick={() => window.location.reload()}
            >
              <img src={LoopLeftIcon as string} alt="Loop Left Icon" />
              Refresh page
            </ActionButton>
          </div>
        );
      }
      if (messageContent.isAppUrl) {
        text = `The app is listening on: ${text}`;
      } else if (messageContent.isSqliteWebUrl) {
        text = `You can inspect your SQLite database in: ${text}`;
      }
      return <TextMessage text={text} format={messageContent.format} />;
    case MessageType.BuilderSignIn:
      return <BuilderSignInMessage />;
    case MessageType.AppStatusChange:
      if (INSTANCE_STATE_DESCRIPTION.has(messageContent.state)) {
        messagesWithDates[messageIndex].hasError =
          messageContent.state === AppInstanceState.ENDED_WITH_ERROR;
        return <InstanceStatusChangeMessage state={messageContent.state} />;
      }
      return null;
    case MessageType.LinkCard:
      return (
        <LinkCardMessage
          body={messageContent.body}
          buttonHref={messageContent.buttonHref}
          buttonText={messageContent.buttonText}
        />
      );
  }
};

const removeRepeatedTimeStamp = (messages: Message[]): Message[] => {
  let tempMessageTime = messages[0].sentAt?.toISOString().slice(0, 19);
  return messages.map((message, index) => {
    const clonedMessage = { ...message };
    if (index > 0) {
      const messageTime = message.sentAt?.toISOString().slice(0, 19);
      if (tempMessageTime === messageTime) {
        tempMessageTime = messageTime;
        clonedMessage.sentAt = undefined;
      } else {
        tempMessageTime = messageTime;
      }
    }
    return clonedMessage;
  });
};

interface MessageToRender {
  message: Message;
  rendered: React.JSX.Element | null;
  errorTraceback: string[];
  isMissingOsEnvVarError: boolean;
}

// eslint-disable-next-line max-lines-per-function
const MessageGroup = (props: MessageListProps) => {
  const { instanceId, isNewVersionAvailable, appId, isAppRunMode } = useContext(AppRunContext);

  const messagesListRef = useRef<HTMLDivElement>(null);
  const isBuilderMessageGroup = props.source.type === MessageSourceType.User;

  const [filteredMessages, setFilteredMessages] = useState<MessageToRender[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line max-lines-per-function
  useEffect(() => {
    const messagesWithDates = [
      ...[
        ...(isBuilderMessageGroup ? [props.messages[props.messages.length - 1]] : props.messages),
      ],
    ];

    const messagesWithoutRepeatedTimestamp = removeRepeatedTimeStamp(messagesWithDates);
    const newFilteredMessages: MessageToRender[] = messagesWithoutRepeatedTimestamp
      .map((message, index) => ({
        message,
        rendered: renderMessage(message.content, index, messagesWithDates, !!appId),
        errorTraceback: [],
        isMissingOsEnvVarError: false,
      }))
      .filter((message) => message.rendered);

    let errorTraceback: string[] = [];
    newFilteredMessages.forEach((filteredMessage, index) => {
      const messageContent = filteredMessage.message.content as TextMessageContent;
      if (messageContent.isErrorTraceback) {
        errorTraceback.push(messageContent.text);

        // Check up to two messages ahead to continue the sequence
        let shouldEndSequence = true;
        for (let i = 1; i <= 2; i++) {
          const nextMessage = newFilteredMessages[index + i]?.message.content as
            | TextMessageContent
            | undefined;
          if (nextMessage?.isErrorTraceback) {
            shouldEndSequence = false; // If any of the next two are error tracebacks, continue
            break;
          }
        }

        if (shouldEndSequence) {
          // End the sequence if no traceback found within the next two messages
          filteredMessage.errorTraceback = [...errorTraceback];
          filteredMessage.isMissingOsEnvVarError =
            doesErrorContainMissingEnvVarReference(errorTraceback);
          errorTraceback = [];
        }
      }
      return filteredMessage;
    });

    setFilteredMessages(newFilteredMessages);
  }, [props.messages]);

  useEffect(() => {
    if (messagesListRef && messagesListRef.current) {
      const copyHandler = (e: ClipboardEvent) => {
        const selectedText = window
          .getSelection()
          ?.toString()
          .replace(/[&][&]timestamp[&][&]\n.*\n[&][&]timestamp[&][&]/g, '');
        e.clipboardData?.setData('text/plain', selectedText || '');
        e.preventDefault();
      };

      messagesListRef.current.addEventListener('copy', copyHandler);

      return () => {
        messagesListRef?.current?.removeEventListener('copy', copyHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (
      filteredMessages.length &&
      // If any of last 3 messages has errorTraceback, scroll automatically
      filteredMessages.slice(-3).some((msg) => msg.errorTraceback.length)
    ) {
      props.scrollDownToLatestMessage?.();
    }
  }, [filteredMessages]);

  const { error } = useChatStore();
  const { emitEvent } = useEventEmitter();

  const llmMetadata = filteredMessages.filter((message) => message.message.metadata).at(-1)
    ?.message.metadata;

  const divChatMessagesAttributes = {
    id: 'chat-messages',
    'data-testid': `group-of-chat-messages-from-${props.source.type.toLowerCase()}`,
    className: classNames('flex flex-col mt-auto mb-auto w-full overflow-hidden chat-message', {
      'text-base': isMobileDevice(),
    }),
    style: { whiteSpace: 'pre-wrap' },
  };

  if (llmMetadata) {
    divChatMessagesAttributes['data-llm-metadata'] = '\n' + yaml.dump(llmMetadata);
  }

  return (
    <div
      ref={messagesListRef}
      className={classNames('flex p-4 gap-3 items-start', {
        'bg-neutral-50': isBuilderMessageGroup,
      })}
    >
      <div className="shrink-0">
        <img
          className="rounded-xl h-6 w-6"
          referrerPolicy="no-referrer"
          src={
            props.source.avatarUrl instanceof Map
              ? props.source.avatarUrl.get('lazy-app-icon')
              : props.source.type === MessageSourceType.User
              ? props.source.avatarUrl || (LazyTestUserAvatar as string)
              : props.source.avatarUrl || (LazyAvatar as string)
          }
          alt=""
        />
      </div>

      <div {...divChatMessagesAttributes}>
        {props.isFirstGroup &&
          useAuthStore.getState().userIsAuthenticated &&
          (!isAppRunMode || (filteredMessages.length > 1 && !error)) &&
          renderLoadPreviousMessagesButton(
            isAppRunMode,
            props.isAllPreviousMessagesLoaded,
            isLoading,
            props.loadMoreMessages,
            setIsLoading,
            instanceId
          )}
        {filteredMessages
          // eslint-disable-next-line max-lines-per-function, complexity
          .map((message, index) => (
            <div className={classNames('flex flex-col gap-1')} key={index}>
              {index === 0 && (
                <div className="flex flex-row text-xs pt-[4px] gap-1 items-center">
                  <div
                    className={classNames('flex font-medium', {
                      'text-primary': props.source.type !== MessageSourceType.User,
                      'text-label-secondary': props.source.type === MessageSourceType.User,
                    })}
                  >
                    {props.source.name instanceof Map &&
                    props.source.type !== MessageSourceType.User
                      ? `${useAppStore.getState().appName.get('lazy-app-name') || ''}${
                          props.sourceSuffix
                        }`
                      : props.source.name || useAuthStore.getState().firebaseUser?.displayName}
                  </div>
                  <div className="flex items-center ml-1">
                    {props.source.type !== MessageSourceType.User &&
                      message.message.appVersionPublishDate && (
                        <div>
                          <UploadCouldLineIcon
                            size={12}
                            color={message.message.lastAppVersionPublished ? '#16A34A' : '#A3A3A3'}
                          ></UploadCouldLineIcon>
                        </div>
                      )}

                    {message.message.lastAppVersionPublished &&
                    message.message.appVersionPublishDate &&
                    !isMobileDevice() ? (
                      <span className="text-system-success">
                        {' '}
                        Current {useAppStore.getState().isTemplate ? 'published' : 'prod'} version
                      </span>
                    ) : message.message.appVersionPublishDate && !isMobileDevice() ? (
                      <span className="text-label-tertiary">
                        Past {useAppStore.getState().isTemplate ? 'published' : 'prod'} version
                      </span>
                    ) : null}
                  </div>
                  {message.message.sentAt && (
                    <div className="select-none ml-auto">
                      <MessageTimestamp
                        date={message.message.sentAt}
                        hasTooltip={false}
                      ></MessageTimestamp>
                    </div>
                  )}
                </div>
              )}
              <div className="flex grow items-center breakLongWords">{message.rendered}</div>
              {message.message?.suggestedAppSkeletons?.length && props.isLastGroup ? (
                <iframe
                  name="lazy-skeleton-picker-app"
                  className={classNames('flex h-[450px] w-full')}
                  src={`${
                    process.env.REACT_APP_LAZY_SKELETON_PICKER_APP_URL as string
                  }/?app_ids=${message.message?.suggestedAppSkeletons
                    .map((skeleton) => skeleton.app_id)
                    .join(',')}&num_preselected=${
                    message.message?.suggestedAppSkeletons.filter(
                      (skeleton) =>
                        skeleton.preselected_rank !== undefined &&
                        skeleton.preselected_rank !== null
                    ).length
                  }`}
                  data-hj-allow-iframe
                  onLoadStart={() => setIsLoading(true)}
                  onLoad={() => {
                    setIsLoading(false);
                  }}
                ></iframe>
              ) : null}
              <div className="flex grow items-center breakLongWords">
                <div className="select-none ml-auto">
                  {index < filteredMessages.length - 1 && message.message.sentAt && (
                    <MessageTimestamp
                      date={message.message.sentAt}
                      hasTooltip={false}
                    ></MessageTimestamp>
                  )}
                </div>
              </div>
              {message.errorTraceback.length >= 1 &&
                renderMessageActionButtons(
                  emitEvent,
                  message.errorTraceback,
                  isNewVersionAvailable,
                  appId,
                  instanceId
                )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MessageGroup;
